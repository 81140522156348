import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';


const routes: Routes = [
  // {
  //   path: "payment/:id",
  //   loadChildren: () => import('./public/payment/payment.module').then(m => m.PaymentModule)
  // },
  {
    path: "payment/:id",
    loadChildren: () => import('./public/core-payment/core-payment.module').then(m => m.CorePaymentModule)
  },
  // {
  //   path: "success",
  //   loadChildren: () => import('./public/success-page/success-page.module').then(m => m.SuccessPageModule)
  // },
  {
    path: "fpx/callback",
    loadChildren: () => import('./public/fpx/callback/callback.module').then(m => m.CallbackModule)
  },
  {
    path: "fpx/status",
    loadChildren: () => import('./public/success-page/success-page.module').then(m => m.SuccessPageModule)
  },
  { path: "rpt/txn", loadChildren: () => import('./secure/rpt/txn-rpt/txn-rpt.module').then(m => m.TxnRptModule) },
  {
    path: "home",
    component: LandingPageComponent
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: '**', component: LandingPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
